import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import { useSelector, useDispatch } from 'react-redux'
import { v4 as uuid4 } from 'uuid'
import { Button, DescriptionTable, Notice, Notification } from 'shared/components'
import { sendErrorReport } from 'shared/helpers'
import { authMethods } from 'shared/constants'
import { updateUserPreferences } from 'src/company/actions'
import { updateUIOptions } from 'src/license/actions'
import {
  getCustomerData,
  getValidDuration,
  getMaintenancePeriod,
  getConsumptionPeriod
} from './helpers'
import SelectedProductsTable from '../SelectedProductsTable'
import SelectedBundlesTable from '../SelectedBundlesTable'
import './styles.scss'

const VerifyOrderStep = ({
  companyID,
  formState,
  handleBack,
  handleOrderCreate,
  loading,
  orderID
}) => {
  const companyProducts = useSelector(state => get(state, 'products.list'))
  const dispatch = useDispatch()
  const uiOptions = useSelector(state => get(state, 'company.userPreferences.ui_options'))
  const userPrefsId = useSelector(state => get(state, 'company.userPreferences.id'))
  const hideSubscriptionNotice = get(uiOptions, 'misc.subscription_type_notice_hide')

  const isBundle = get(formState, 'is_bundle')
  const bundle = get(formState, 'bundle')

  const [generatingLicenses, setGeneratingLicenses] = useState(false)

  const products = get(formState, 'products') || []
  const hasCustomer = get(formState, 'add_customer')
  const existingCustomer = get(formState, 'existingCustomer')
  const customerData = {
    id: getCustomerData(formState, existingCustomer, 'id', 'id'),
    email: getCustomerData(formState, existingCustomer, 'customerEmail', 'email'),
    first_name: getCustomerData(formState, existingCustomer, 'customerFirstName', 'first_name'),
    last_name: getCustomerData(formState, existingCustomer, 'customerLastName', 'last_name'),
    address: getCustomerData(formState, existingCustomer, 'customerAddress', 'address'),
    city: getCustomerData(formState, existingCustomer, 'customerCity', 'city'),
    state: getCustomerData(formState, existingCustomer, 'customerState', 'state'),
    country: getCustomerData(formState, existingCustomer, 'customerCountry', 'country'),
    postcode: getCustomerData(formState, existingCustomer, 'customerZipcode', 'postcode'),
    phone: getCustomerData(formState, existingCustomer, 'customerPhoneNumber', 'phone'),
    company_name: getCustomerData(
      formState,
      existingCustomer,
      'customerOrganization',
      'company_name'
    ),
    reference: getCustomerData(formState, existingCustomer, 'customerReference', 'reference'),
    is_manager: get(formState, 'is_manager')
  }

  const handleBackClick = () => handleBack()

  const handleBundleOrderCreateClick = async () => {
    if (loading || generatingLicenses) {
      return false
    }
    setGeneratingLicenses(true)

    const uuidID = uuid4()
      .split('-')
      .join('')

    const bundleCode = get(bundle, 'bundle.data.short_code')
    const isBundleKeyAuth = get(bundle, 'bundle.data.authorization_method') === authMethods.key

    const bundlePolicies = get(bundle, 'selected_bundle_policies') || []
    const bundleProducts = bundlePolicies.map(p => {
      const prod = companyProducts.find(i => i.id === p.product)
      return {
        license_template_code: p.code,
        product_code: prod.short_code
      }
    })

    const bundleItem = {
      product_code: bundleCode,
      users: isBundleKeyAuth ? undefined : [],
      licenses: bundleProducts
    }

    const data = {
      id: get(formState, 'orderId') || uuidID,
      is_bundle: true,
      append: !!orderID,
      customer: hasCustomer ? customerData : null,
      items: [bundleItem],
      reference: get(formState, 'orderReference') || ''
    }
    handleOrderCreate(data)
    setGeneratingLicenses(false)
    return true
  }

  const handleOrderCreateClick = async () => {
    if (loading || generatingLicenses) {
      return false
    }
    setGeneratingLicenses(true)

    const orderItems = await Promise.all(
      products.map(async product => {
        const productCode = get(product, 'short_code')
        const licenseNum = Number(get(product, 'license_num'))
        const productFeatures = get(product, 'selected_features') || []
        const customFields = get(product, 'selected_custom_fields') || []
        const isKeyAuth = get(product, 'authorization_method') === authMethods.key
        const isSubscription = get(product, 'default_license_type') === 'subscription'

        let item

        if (isKeyAuth) {
          const isAirGapped = get(product, 'is_air_gapped')
          const template = isAirGapped ? get(product, 'selected_license_policy.id') : undefined

          item = {
            product_code: productCode,
            note: get(product, 'note'),
            licenses: [...Array(licenseNum)].map(() => ({
              max_activations: get(product, 'max_activations'),
              allow_unlimited_activations: get(product, 'allow_unlimited_activations'),
              is_trial: get(product, 'allow_trial'),
              trial_days: get(product, 'trial_days') || 0,
              license_type: get(product, 'default_license_type'),
              valid_duration: getValidDuration(product),
              validity_period: isSubscription ? undefined : get(product, 'validity_period'), // integration should handle this
              allow_grace_period: get(product, 'allow_grace_period'),
              grace_period: get(product, 'grace_period'),
              prevent_vm: get(product, 'prevent_vm'),
              max_consumptions: Number(get(product, 'max_consumptions')),
              allow_unlimited_consumptions: get(product, 'allow_unlimited_consumptions'),
              allow_negative_consumptions: get(product, 'allow_negative_consumptions'),
              allow_overages: get(product, 'allow_overages'),
              max_overages: Number(get(product, 'max_overages')),
              reset_consumption: get(product, 'reset_consumption'),
              consumption_period: getConsumptionPeriod(product),
              enable_maintenance_period: get(product, 'enable_maintenance_period'),
              maintenance_duration: getMaintenancePeriod(product),
              is_floating: get(product, 'is_floating'),
              is_floating_cloud: get(product, 'is_floating_cloud'),
              floating_users: Number(get(product, 'floating_users')),
              floating_timeout: Number(get(product, 'floating_timeout')),
              max_transfers: get(product, 'max_transfers')
                ? Number(get(product, 'max_transfers'))
                : 0,
              start_date: get(product, 'start_date') || null,
              is_air_gapped: isAirGapped,
              can_borrow: get(product, 'can_borrow') || false,
              is_hardware_key_auth: get(product, 'is_hardware_key_auth') || false,
              max_borrow_time: get(product, 'max_borrow_time') || 0,
              license_template: template,
              metadata: get(product, 'metadata') || '',
              product_features: productFeatures.map(feature => ({
                code: get(feature, 'data.code') || get(feature, 'code'),
                max_consumption: Number(get(feature, 'max_consumption')),
                allow_unlimited_consumptions: get(feature, 'allow_unlimited_consumptions') || false,
                allow_negative_consumptions: get(feature, 'allow_negative_consumptions') || false,
                allow_overages: get(feature, 'allow_overages') || false,
                max_overages: get(feature, 'max_overages') || 0,
                reset_consumption: get(feature, 'reset_consumption') || false,
                consumption_period: get(feature, 'consumption_period'),
                expiry_date: get(feature, 'expiry_date') || null,
                metadata: get(feature, 'metadata') || '',
                is_floating: get(feature, 'is_floating') || '',
                is_floating_cloud: get(feature, 'is_floating_cloud') || '',
                floating_timeout: get(feature, 'floating_timeout') || '',
                floating_users: get(feature, 'floating_users') || ''
              })),
              custom_fields: customFields.map(cf => ({
                name: get(cf, 'label'),
                value: get(cf, 'cf_value')
              }))
            }))
          }
        }

        if (!isKeyAuth) {
          let users
          const licenseUsers = get(product, 'license_users') || null
          const isAirGapped = get(product, 'is_air_gapped')
          const template = isAirGapped ? get(product, 'selected_license_policy.id') : undefined
          if (Array.isArray(licenseUsers)) {
            users = licenseUsers.map(lu => ({
              email: lu,
              is_manager: false
            }))
          }

          item = {
            product_code: productCode,
            note: get(product, 'note'),
            licenses: [
              {
                max_activations: get(product, 'max_activations'),
                allow_unlimited_activations: get(product, 'allow_unlimited_activations'),
                users: users || null,
                is_trial: get(product, 'allow_trial'),
                trial_days: get(product, 'trial_days') || 0,
                license_type: get(product, 'default_license_type'),
                valid_duration: getValidDuration(product),
                validity_period: get(product, 'validity_period'),
                allow_grace_period: get(product, 'allow_grace_period'),
                grace_period: get(product, 'grace_period'),
                prevent_vm: get(product, 'prevent_vm'),
                max_consumptions: Number(get(product, 'max_consumptions')),
                allow_unlimited_consumptions: get(product, 'allow_unlimited_consumptions'),
                allow_negative_consumptions: get(product, 'allow_negative_consumptions'),
                allow_overages: get(product, 'allow_overages'),
                max_overages: Number(get(product, 'max_overages')),
                reset_consumption: get(product, 'reset_consumption'),
                consumption_period: getConsumptionPeriod(product),
                enable_maintenance_period: get(product, 'enable_maintenance_period'),
                maintenance_duration: getMaintenancePeriod(product),
                is_floating: get(product, 'is_floating'),
                is_floating_cloud: get(product, 'is_floating_cloud'),
                floating_users: Number(get(product, 'floating_users')),
                floating_timeout: Number(get(product, 'floating_timeout')),
                max_transfers: get(product, 'max_transfers')
                  ? Number(get(product, 'max_transfers'))
                  : 0,
                product_features: productFeatures.map(feature => ({
                  code: get(feature, 'data.code') || get(feature, 'code'),
                  max_consumption: Number(get(feature, 'max_consumption')),
                  allow_unlimited_consumptions:
                    get(feature, 'allow_unlimited_consumptions') || false,
                  allow_negative_consumptions: get(feature, 'allow_negative_consumptions') || false,
                  allow_overages: get(feature, 'allow_overages') || false,
                  max_overages: get(feature, 'max_overages') || 0,
                  reset_consumption: get(feature, 'reset_consumption') || false,
                  consumption_period: get(feature, 'consumption_period'),
                  expiry_date: get(feature, 'expiry_date') || null,
                  metadata: get(feature, 'metadata') || '',
                  is_floating: get(feature, 'is_floating') || '',
                  is_floating_cloud: get(feature, 'is_floating_cloud') || '',
                  floating_timeout: get(feature, 'floating_timeout') || '',
                  floating_users: get(feature, 'floating_users') || ''
                })),
                custom_fields: customFields.map(cf => ({
                  name: get(cf, 'label'),
                  value: get(cf, 'cf_value')
                })),
                max_license_users: get(product, 'max_license_users'),
                start_date: get(product, 'start_date') || null,
                is_air_gapped: isAirGapped,
                can_borrow: get(product, 'can_borrow') || false,
                is_hardware_key_auth: get(product, 'is_hardware_key_auth') || false,
                max_borrow_time: get(product, 'max_borrow_time') || 0,
                metadata: get(product, 'metadata') || '',
                license_template: template
              }
            ]
          }
        }

        return item
      })
    )

    const uuidID = uuid4()
      .split('-')
      .join('')

    const data = {
      id: get(formState, 'orderId') || uuidID,
      append: !!orderID,
      customer: hasCustomer ? customerData : null,
      items: orderItems,
      reference: get(formState, 'orderReference') || ''
    }

    handleOrderCreate(data)
    setGeneratingLicenses(false)
    return true
  }

  const hideSubscriptionTypeNotice = () => {
    if (!userPrefsId) {
      Notification(
        'error',
        __('Your changes were not saved'),
        __("Superadmin doesn't have user preferences ID")
      )
      return false
    }
    const ui = {
      ...uiOptions,
      misc: { subscription_type_notice_hide: true }
    }
    const data = { ui_options: JSON.stringify(ui) }

    updateUIOptions(userPrefsId, companyID, data)
      .then(res => {
        dispatch(updateUserPreferences(res.data))
      })
      .catch(err => sendErrorReport(err, 'Ui options update failed', data))
    return true
  }

  const hasSubscription = products.some(p => p.default_license_type === 'subscription')
  const orderReference = get(formState, 'orderReference')

  return (
    <div className='VerifyOrderStep'>
      <section className='VerifyOrderStep-customer'>
        {orderID || get(formState, 'orderId') ? (
          <div className='section-header order-id'>
            {`${__('Order ID')}:`}
            <span>{get(formState, 'orderId')}</span>
          </div>
        ) : null}
        <div className='section-header reference'>
          {orderReference && (
            <>
              {`${__('Order reference')}:`}
              <span>{orderReference}</span>
            </>
          )}
        </div>
        <div className='section-header'>{__('Customer details')}</div>
        {hasCustomer ? (
          <DescriptionTable
            details={[
              { label: __('Email'), value: customerData.email },
              { label: __('First Name'), value: customerData.first_name },
              { label: __('Last Name'), value: customerData.last_name },
              { label: __('Address'), value: customerData.address },
              { label: __('City'), value: customerData.city },
              { label: __('State / Province'), value: customerData.state },
              { label: __('Country'), value: customerData.country },
              { label: __('Zipcode / Postcode'), value: customerData.postcode },
              { label: __('Phone Number'), value: customerData.phone },
              { label: __('Company Name'), value: customerData.company_name },
              { label: __('Reference'), value: customerData.reference },
              {
                label: __('Is license manager'),
                value: customerData.is_manager ? __('Yes') : __('No')
              }
            ]}
          />
        ) : (
          <div className='no-customer-added'>{__('No customer defined')}</div>
        )}
      </section>
      <section className='VerifyOrderStep-products'>
        <div className='section-header'>{__('Order products')}</div>
        {isBundle ? (
          <SelectedBundlesTable
            companyID={companyID}
            selectedBundles={[bundle]}
            showActionBtns={false}
          />
        ) : (
          <SelectedProductsTable
            companyID={companyID}
            selectedProducts={products}
            showActionBtns={false}
          />
        )}
      </section>
      {hasSubscription && !hideSubscriptionNotice && false && (
        <div className='subscription-type-notice'>
          <Notice theme='warning' title={__('Subscription license type notice')} size='sm'>
            <div className='actionable-notice'>
              <div className='text'>
                {__(
                  'LicenseSpring requires an integration to an external source of truth (like a recurring billing system). LicenseSpring updates the status of the license according to the status of the subscription, which is handled by a 3rd party. Without this integration, subscription licenses remain valid until otherwise specified'
                )}
              </div>
              <div className='actions'>
                <Button size='sm' onClick={hideSubscriptionTypeNotice}>
                  {__("Don't show this again")}
                </Button>
              </div>
            </div>
          </Notice>
        </div>
      )}
      <div className='ProductsStep-actions'>
        <Button
          disabled={loading || generatingLicenses}
          onClick={handleBackClick}
          size='lg'
          theme='default'
        >
          {__('Back')}
        </Button>
        <Button
          disabled={loading || generatingLicenses}
          onClick={() => {
            if (isBundle) {
              handleBundleOrderCreateClick()
              return true
            }
            handleOrderCreateClick()
            return true
          }}
          size='lg'
          theme='success'
        >
          {__('Create order')}
        </Button>
      </div>
    </div>
  )
}

VerifyOrderStep.propTypes = {
  companyID: PropTypes.number.isRequired,
  formState: PropTypes.object.isRequired,
  handleBack: PropTypes.func.isRequired,
  handleOrderCreate: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  orderID: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
}

VerifyOrderStep.defaultProps = {
  loading: false,
  orderID: null
}

export default VerifyOrderStep
