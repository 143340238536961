import React, { useState } from 'react'
import { get } from 'lodash'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import { Prompt } from 'react-router-dom'
import { sendErrorReport, checkUserPermission, isFeatureEnabled } from 'shared/helpers'
import { userPermissions, platformFeatures } from 'shared/constants'
import {
  Button,
  Label,
  Notice,
  Notification,
  PermissionMissingNotificationTitle,
  RichTextEditor,
  Subtitle
} from 'shared/components'
import { getCompanySilent } from 'src/company/actions'
import { updateCompany } from 'src/account/actions'
import './styles.scss'

const EmailSettings = ({ setDisableTabsChange }) => {
  const [editorId, setEditorId] = useState(1)
  const canManageSettings = checkUserPermission(userPermissions.settings_write)
  const dispatch = useDispatch()
  const companyDetails = useSelector(state => get(state, 'company.details'))
  const companyID = get(companyDetails, 'id')
  const isEmailNotificationsTemplatesFeatureEnabled = isFeatureEnabled(
    platformFeatures.extra_email_notifications_templates
  )

  const [isLoading, setLoading] = useState(false)
  const [isDirty, setDirty] = useState(false)
  const [text, setText] = useState(get(companyDetails, 'email_footer') || '')

  setDisableTabsChange(isDirty)

  const resetValues = () => {
    setText(get(companyDetails, 'email_footer') || '')
    setEditorId(editorId + 1)
    setDirty(false)
  }

  const handleSubmit = () => {
    if (isLoading || !isDirty) {
      return false
    }
    const data = {
      email_footer: text
    }
    setLoading(true)

    updateCompany(companyID, data)
      .then(() => {
        setLoading(false)
        setDirty(false)
        dispatch(getCompanySilent(companyID))
        Notification('success', __('Changes saved successfully'))
      })
      .catch(err => {
        setLoading(false)
        setDirty(false)
        sendErrorReport(err, 'Cannot update email footer', data)
        Notification(
          'error',
          __('Your changes were not saved'),
          __('There was an error while saving your changes')
        )
      })
    return true
  }

  const handleManageNotificationsClick = cb => {
    if (!canManageSettings) {
      Notification(
        'error',
        <PermissionMissingNotificationTitle permission={userPermissions.settings_write} />,
        __('Contact you account admin for support.')
      )
      return false
    }
    cb(true)
    return true
  }

  return (
    <div className='EmailSettings'>
      <Prompt
        when={isDirty}
        message={__('You have unsaved changes. Are you sure you want to leave?')}
      />
      {!isEmailNotificationsTemplatesFeatureEnabled && (
        <Notice
          size='sm'
          title={__('Customizing email templates is not available in your plan type.')}
          theme='warning'
        >
          <div>{__('Upgrade your plan to use this feature.')}</div>
        </Notice>
      )}

      <div className='EmailSettings-title'>
        <Subtitle text={__('Email notification settings')} />
      </div>
      <div className='Wrapper'>
        <div className='Configurator'>
          <div className='form-row'>
            <Label inputId='text-input' text={__('Email footer')} />
            <RichTextEditor
              id={editorId}
              content={text}
              onChange={val => {
                setDirty(true)
                setText(val)
              }}
            />
          </div>

          <div className='buttons-container'>
            <Button
              theme='success'
              disabled={isLoading || !isEmailNotificationsTemplatesFeatureEnabled}
              onClick={() => handleManageNotificationsClick(handleSubmit)}
            >
              {__('Save')}
            </Button>
            <Button
              theme='info'
              disabled={isLoading || !isEmailNotificationsTemplatesFeatureEnabled}
              onClick={() => handleManageNotificationsClick(resetValues)}
            >
              {__('Cancel changes')}
            </Button>
          </div>
        </div>
        <div></div>
      </div>
    </div>
  )
}

EmailSettings.propTypes = {
  setDisableTabsChange: PropTypes.func
}

EmailSettings.defaultProps = {
  setDisableTabsChange: () => {}
}

export default EmailSettings
